import React from 'react';
import PropTypes from 'prop-types';
import { intlShape } from 'react-intl';
import { useStaticQuery, graphql } from 'gatsby';

const useSiteMetadata = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          image
          siteUrl
        }
      }
    }
  `);

  return data.site.siteMetadata;
};

const SEO = ({ titleKey, descriptionKey, intl, children }) => {
  const { title: defaultTitle, description: defaultDescription, image, siteUrl } = useSiteMetadata();
  const title = intl.messages[titleKey];
  const hasDescriptionKey = !!descriptionKey;
  const description = hasDescriptionKey ? intl.messages[descriptionKey] : '';
  const seo = {
    title: title ? `${title} | ${defaultTitle}` : defaultTitle,
    description: hasDescriptionKey ? description || defaultDescription : '',
    image: `${siteUrl}${image}`,
  };
  return (
    <>
      <title>{seo.title}</title>
      {hasDescriptionKey && <meta name="description" content={seo.description} />}
      <meta name="og:title" content={seo.title} />
      {/*// TODO: Determine social media site image*/}
      {/*<meta name="image" content={seo.image} />*/}
      {hasDescriptionKey && <meta name="og:description" content={seo.description} />}
      <meta name="og:type" content="website" />
      {children}
    </>
  );
};

SEO.defaultProps = {
  descriptionKey: '',
};

SEO.propTypes = {
  titleKey: PropTypes.string.isRequired,
  descriptionKey: PropTypes.string,
  intl: intlShape.isRequired,
  children: PropTypes.node,
};

export default SEO;
