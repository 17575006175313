import React from 'react';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';

import './Loading.scss';

/**
 * Spinner component to display in loading screens. If no props are given, component will render
 * a default light css spinner. The spinner can be customized through providing a custom image to render as the spinner
 * or a custom css class as the spinner. If no spinnerClassName is provided the 'light' spinner will render.
 * The 'light', 'dark', and 'dark-large' spinners are packaged with the component. A consumer can implement either by specifying
 * them in the spinnerClassName
 */
const Loading = injectIntl(({ intl, spinnerClassName, imgSrc, altText }) => {
  return (
    <div id="wait-spinner" data-testid="loading" className={imgSrc ? '' : spinnerClassName}>
      {imgSrc && <img src={imgSrc} alt={altText} />}
      <span style={{ display: 'none' }}>{intl.formatMessage({ id: 'common.loading' })}</span>
    </div>
  );
});

Loading.propTypes = {
  /** Internationalization object */
  intl: intlShape,
  /** Optional custom css for loader */
  spinnerClassName: PropTypes.string,
  /** Optional image to display as loader */
  imgSrc: PropTypes.string,
  /** Alt text for display images. When display image is passed, please don't forget to pass in alt text */
  altText: (props, propName) => {
    if (props.imgSrc && !props[propName]) {
      return new Error('You need to provide an altText when you provide an image');
    }

    return null;
  },
};

Loading.defaultProps = {
  spinnerClassName: 'light',
};

export default Loading;
