import React, { useState } from 'react';
import { injectIntl, FormattedMessage } from 'react-intl';
import {
  Footer,
  TextField,
  Button,
  Icon,
  Link,
  useForm,
  LayoutWrapper,
  Modal,
} from '@cof/lighthouse-component-library';
import SEO from '../components/SEO/SEO';
import WebFooterDefaultText from '../components/WebFooterDefaultText';
import C1Logo from '../assets/images/c1-logo.svg';
import Loading from '../components/Loading/Loading.js';

import './get-a-card.scss';

const UA = injectIntl(({ intl }) => {
  const codeMask = {
    mask: 'XXXX-XXXX-XXXX-XXXX',
    staticIndexes: [4, 9, 14],
    validInputRegex: /[0-9]/g,
  };
  const [code, setCode] = useState(codeMask.mask);
  const [isLoading, setLoading] = useState(false);
  const [faqToggle, setFaqToggle] = useState(false);
  const [url, setUrl] = useState('');
  const { validateAndSubmit, register, onValidationError, onValidationSuccess } = useForm({
    customSubmitValidator: () => {
      return validateCode(code);
    },
  });

  const validateCode = (code) => {
    const pageList = {
      '01': 'https://findyourcard.capitalone.ca/',
    };
    const cardList = {
      '07': null,
      21: 'Z20%3D',
      28: 'bmZzcm0%3D',
    };
    const validTestCells = {
      241510: 'WWW_ZZZQK_ZQU_ZZZZUE_1ZZGM_ZZ_CT_Z_UA102024TC15',
    };
    let segments = code.replace(/-/g, '').match(/.{2}/g);
    let lastSixDigits = [segments[5], segments[6], segments[7]].join('');
    if (segments[0] !== '31') {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    if (segments[1] !== '16') {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    if (segments[2] !== '00') {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    if (!Object.keys(pageList).includes(segments[3])) {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    if (!Object.keys(cardList).includes(segments[4])) {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    if (!Object.keys(validTestCells).includes(lastSixDigits)) {
      onValidationError({
        name: 'reservation',
        errorMessage: intl.formatMessage({ id: 'ua-landing.error' }),
      });
      setLoading(false);
      return false;
    }
    // NOTE: If pageList increases to include other sites like homepage, need to change below logic to include /fr where as findyourcard page uses &lang=fr-ca
    setUrl(
      `${pageList[segments[3]]}?external_id=${validTestCells[lastSixDigits]}${
        intl.locale === 'fr' ? '&lang=fr-ca' : ''
      }${cardList[segments[4]] ? `&qkref=${cardList[segments[4]]}` : ''}`,
    );
    onValidationSuccess('reservation');
    return true;
  };

  const navBar = [
    {
      text: intl.formatMessage({ id: 'navfooter.legal.privacy' }),
      url: `https://www.capitalone.ca/privacypolicy/${intl.locale === 'fr' ? 'fr' : ''}`,
    },
    {
      text: intl.formatMessage({ id: 'navfooter.legal.security' }),
      url: `https://www.capitalone.ca/about/security/${intl.locale === 'fr' ? 'fr' : ''}`,
    },
    {
      text: intl.formatMessage({ id: 'navfooter.legal.terms' }),
      url: `https://www.capitalone.ca/about/terms-of-use/${intl.locale === 'fr' ? 'fr' : ''}`,
    },
  ];
  return (
    <section className="ua-landing-page">
      <main>
        <LayoutWrapper hideVerticalGap className="ua-layout">
          <Link className="ua-logo" to="/">
            <img src={C1Logo} alt="Capital One Logo" />
          </Link>
          <div className="ua-header">
            <div className="ua-welcome">
              <h1 className="ua-title">{intl.formatMessage({ id: 'ua-landing.hero-title' })}</h1>
              <p className="ua-description">
                <FormattedMessage
                  id="ua-landing.hero-desc"
                  values={{
                    boldText: (
                      <b>
                        <FormattedMessage id="ua-landing.hero-bold" />
                      </b>
                    ),
                  }}
                />
              </p>
            </div>
            <div className="ua-faq">
              <Button isLink onClick={() => setFaqToggle(true)}>
                {intl.formatMessage({ id: 'ua-landing.faq.title' })}
              </Button>
              <Modal
                id="faq"
                isOpen={faqToggle}
                onClose={() => setFaqToggle(false)}
                closeButtonLabel="Close"
                header={intl.formatMessage({ id: 'ua-landing.faq.title' })}
              >
                <section className="ua-faq-modal">
                  <FormattedMessage id="ua-landing.faq.h1" />
                  <p>{intl.formatMessage({ id: 'ua-landing.faq.p1' })}</p>
                  <FormattedMessage id="ua-landing.faq.h2" />
                  <p>{intl.formatMessage({ id: 'ua-landing.faq.p2' })}</p>
                  <FormattedMessage id="ua-landing.faq.h3" />
                  <p>{intl.formatMessage({ id: 'ua-landing.faq.p3' })}</p>
                  <FormattedMessage id="ua-landing.faq.h4" />
                  <p>
                    {' '}
                    <FormattedMessage
                      id="ua-landing.faq.p4"
                      values={{
                        link: (
                          <Link
                            to={
                              intl.locale === 'fr'
                                ? 'https://www.capitalone.ca/assets/documents/pdf/CapitalOne_AutoPaySignUpSheet_French.pdf'
                                : 'https://www.capitalone.ca/assets/documents/pdf/CapitalOne_AutoPaySignUpSheet_English.pdf'
                            }
                          >
                            {intl.formatMessage({ id: 'ua-landing.faq.link' })}
                          </Link>
                        ),
                      }}
                    />
                  </p>
                </section>
              </Modal>
            </div>
          </div>
        </LayoutWrapper>
        <LayoutWrapper hideVerticalGap className="ua-layout">
          <div className="ua-content-box">
            <div className="ua-left-box">
              <form
                onSubmit={(e) => {
                  setLoading(true);
                  validateAndSubmit(() => {
                    if (url.includes('external_id') && url.includes('capitalone.ca')) {
                      window.open(url, '_self');
                    }
                  }, e);
                }}
              >
                <TextField
                  {...register({
                    onBlur: () => {
                      validateCode(code);
                    },
                    name: 'reservation',
                    onChange: () => {},
                    baseValidation: {
                      type: 'text',
                      strategies: ['onBlur', 'onSubmit'],
                      errorMessages: {
                        EMPTY_FIELD: 'empty field',
                      },
                    },
                    maskOptions: {
                      setter: setCode,
                      maskObject: codeMask,
                    },
                  })}
                  ariaLabel={intl.formatMessage({ id: 'ua-landing.input-label' })}
                  label={intl.formatMessage({ id: 'ua-landing.input-label' })}
                  value={code}
                />
                <Button isSubmit width="fill" size="regular">
                  {isLoading ? <Loading /> : intl.formatMessage({ id: 'ua-landing.button' })}
                </Button>
              </form>
              <div className="ua-secure">
                <Icon name="lock" className="icon-lock" />
                <h2>{intl.formatMessage({ id: 'ua-landing.secure' })}</h2>
              </div>
              <p className="ua-safety">
                <FormattedMessage
                  id="ua-landing.secure-desc"
                  values={{
                    link: (
                      <Link
                        to={
                          intl.locale === 'fr'
                            ? 'https://www.capitalone.ca/about/security/fr/'
                            : 'https://www.capitalone.ca/about/security/'
                        }
                      >
                        <FormattedMessage id="ua-landing.secure-desc-link" />
                      </Link>
                    ),
                  }}
                />
              </p>
            </div>
            <div className="ua-right-box">
              <h2 className="ua-right-title">{intl.formatMessage({ id: 'ua-landing.reservation-question' })}</h2>
              <p className="ua-right-description">{intl.formatMessage({ id: 'ua-landing.reservation-desc' })}</p>
              <div className="ua-grey-box">
                <p>{intl.formatMessage({ id: 'ua-landing.reservation-code' })}</p>
                <span>00XX-XXXX-XXXX-XXXX</span>
              </div>
            </div>
          </div>
        </LayoutWrapper>
      </main>

      <footer>
        <LayoutWrapper hideVerticalGap className="ua-footer-layout">
          <Footer
            hidePromo
            hideSocial
            forceLogoLeftAlign
            copyrightText={<WebFooterDefaultText />}
            intl={intl}
            navBar={navBar}
          />
        </LayoutWrapper>
      </footer>
    </section>
  );
});

export default UA;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO titleKey="ua-landing.seo-title" descriptionKey="ua-landing.seo-desc" intl={pageContext.intl} />
);
